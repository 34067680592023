
  import {mapGetters} from 'vuex'
  import {inject} from "vue";

  export default {
    setup() {
      const ionRouter = inject("navManager")

      return {
        ionRouter,
      }
    },
    data() {
      return {
        version: process.env.VUE_APP_VERSION,
        logoutConfirmation: false,
        logoutConfirmed: false
      }
    },
    computed: {
      ...mapGetters('wAuth', ['user']),
    },

    watch: {
      $route (to, from){
        this.logoutConfirmation = false
        if (to.fullPath == '/app/profile')
          this.logoutConfirmed = false
      }
    },

    methods: {
      logOut() {
        this.logoutConfirmation = true
      },

      logOutConfirm() {
        this.logoutConfirmed = true
        this.$store.dispatch('wAuth/logout')
        this.$router.push('login')
        window.location.reload()
      },

      deleteProfile() {
        this.ionRouter.navigate({
          routerDirection: "none",
          routerLink: "/app/delete-profile",
        })
      },
    }
  }
